<template>
  <v-container fluid>
    <FilterDrawer
      current-component="agencies-filter"
      @filter="filterAgencies"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.company_name }}</td>
        <td>{{ item.trading_name }}</td>
        <td>{{ item.name }}</td>
        <td class="text-center">
          {{ item.email }}
        </td>
        <td class="text-center">
          {{ item.contact }}
        </td>
        <td class="text-center">
          {{ item.seller }}
        </td>
        <td class="text-right">
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    isFilterOpened: false,
    dataTable: {
      title: 'Agências',
      new: 'agencies-new',
      edit: 'agencies-edit',
      context: 'agencies-context',
      massAction: 'agencies-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Razão Social', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'trading_name' },
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'contact', align: 'center' },
        { text: 'Vendedor', value: 'seller', align: 'center' },
        { text: 'Status', value: 'status', align: 'end' },
      ],
    },
  }),
  mounted() {
    this.getAgencies()
  },
  methods: {
    setCurrentPage(page) {
      this.getAgencies(page)
    },
    search(search) {
      this.currentSearch = search
      this.getAgencies()
    },
    getAgencies(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/agencies', {
          params: {
            page,
            search: this.currentSearch,
          },
        },{
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.last_page;
          this.dataTable.items = res.data.data
          this.dataTable.totalRegister = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterAgencies(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/agency', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getAgencies()
    },
  },
}
</script>
